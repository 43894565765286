/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ lang, meta, locales, title }) {

  const cookieAccepted = (name) => {
    var re = new RegExp(`${name}=([^;]+)`)
    var value = re.exec(typeof document !== 'undefined' && document.cookie)
    return value != null ? unescape(value[1]) : null
  }

  const [statisticCookies, setStatistCookies] = useState(false)
  const [marketingCookies, setMarketingCookies] = useState(false)

  if(cookieAccepted('rcl_statistics_consent') && !statisticCookies) {
    setStatistCookies(true)
  }
  if(cookieAccepted('rcl_marketing_consent')  && !marketingCookies) {
    setMarketingCookies(true)
  }

  const { site, cookie } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        cookie: storyblokEntry(field_component: {eq: "cookie_consent"}) {
          content
        }
      }
    `
  )
  const cookieContent = JSON.parse(cookie && cookie.content)
  const statisticsScripts = cookieContent && cookieContent.statistic_scripts
  const statisticsScript = cookieContent && cookieContent.statistic_scripts[0].script
  const marketingScripts = cookieContent && cookieContent.marketing_scriptsz

  const url = typeof window !== 'undefined' ? window.location.origin : '';
  const metaDescription = meta.description
  return (
    <Helmet
      htmlAttributes={{
        lang: lang === 'default' ? 'da' : lang,
      }}
      title={site.siteMetadata.title}
      titleTemplate={`${meta.title || title} | %s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: meta.og_title || meta.title,
        },
        {
          property: `og:image`,
          content: meta.og_image,
        },
        {
          property: `og:description`,
          content: meta.og_description || metaDescription
        },
        {
          name: `twitter:title`,
          content: meta.twitter_title || meta.title,
        },
        {
          name: `twitter:image`,
          content: meta.twitter_image || meta.og_image,
        },
        {
          name: `twitter:description`,
          content: meta.twitter_description || metaDescription,
        },
      ]}
    > 
    {locales && locales.length > 0 && locales.map((locale, i) => 
        <link rel="alternate" href={`${url}${locale.path !== '__home__' ? `${locale.lang === 'default' ? '' : '/' + locale.lang}/${locale.path || ''}` : `/${locale.lang.replace('default', '')}`}`} hrefLang={locale.lang.replace('default', 'da')} key={i}/>
    )}
    
    {/* Google Analytics */}
    {statisticCookies ?
      statisticsScripts && statisticsScripts.map((st, i) => (
        <script 
          key={i} 
          async 
          src={"https://www.googletagmanager.com/gtag/js?id=" + statisticsScript}
          >
        </script>
      ))
    : null}

    {statisticCookies ?
      <script type="text/javascript">
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'UA-40432081-1');`}
      </script>

    : null}

    {/* Facebook Pixel */}
      <meta name="facebook-domain-verification" content="dk627uussjv0g30rmxocvw3o7mxm1n"/>

    {marketingCookies ?
      marketingScripts && marketingScripts.map((mt, i) => (

        <script type="text/javascript" key={i}>
          {`!function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', ${mt.script});
          fbq('track', 'PageView');`}
        </script>
      ))
    : null}

    {marketingCookies ?
      marketingScripts && marketingScripts.map((mt, i) => (
        <noscript key={i}>
        {`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${mt.script}&ev=PageView&noscript=1"/>`}
      </noscript>
      ))
    : null}



    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `da`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.object,
  locales: PropTypes.arrayOf(PropTypes.object),
}

export default SEO
